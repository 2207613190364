/* You can add global styles to this file, and also import other style files */
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css");


.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }

  /* Modify the backgorund color */
  .navbar-custom {
    background-color: #327247;
  }

  /* Modify brand and text color */
  .navbar-custom .navbar-brand,
  .navbar-custom .navbar-text {
    color: whitesmoke;
  }

  .navbar-brand {
    font-weight: 700;
    font-size: 1.5rem;
  }


  /* Show it's not fixed to the top */
body {
  min-height: 75rem;
}


/* ---------------------------------------------------------------------- */
/*  Social 
/* ---------------------------------------------------------------------- */

#social {
  background:url('assets/images/textures/texture1.png'); /* the texture can be changed see img/textures/ */
  border-bottom:1px solid #ccc;
  -webkit-box-shadow: 5px 0px 5px rgba(0, 0, 0, .2);
       -moz-box-shadow: 5px 0px 5px rgba(0, 0, 0, .2);
            box-shadow: 5px 0px 5px rgba(0, 0, 0, .2);
}

.social li a, input, textarea {
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

h3 {
  font-family: "LeagueGothicRegular", "Helvetica Neue", Helvetica, Arial;
}

.form-control, .form-control:focus{
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  border: rgba(255, 255, 255, 0);
}

.card-link{
  font-size: .90rem;
  text-decoration: none;
  float: right;
}
.instagram{ width:100px; height:100px;
  background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }

  .navlink > a{
    text-decoration: none;
    color: inherit !important;
  }

  ul.services {
    list-style: none;
  }
  
  ul.services li:before {
    content: '✓ ';
  }

  .img-gal{
    padding: 0;
  }